import _ from 'lodash';
import i18n from 'i18next';
import axios from 'axios';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';
import { SECRET_KEY } from './../configs';
import { alert } from './../actions';
import { Icon } from '@iconify/react';

const timezone = localStorage.hasOwnProperty('timezone') ? localStorage.getItem('timezone') : moment.tz.guess();
moment.tz.setDefault(timezone);

export const getWebDomain = () => {
	return new Promise((reslove)=>{
		const { origin } = window.location;
		reslove(origin);
	});
};

export const getFileUrl = file => new Promise((resolve, reject) => {
  axios.post('files/url', file).then(response => {
    resolve((response?.data?.fileUrl !== 'null' && response?.data?.fileUrl) || '');
  }).catch(() => {
    reject();
  });
});

export const uploadFileBase64 = (name, file) => new Promise((resolve, reject) => {
  axios.post('files/upload', { file: file.base64 }).then(response => {
    resolve((response?.data?.data !== 'null' && response?.data?.data) || (response?.data !== 'null' && { ...response?.data, name, size: file.size, type: file.type }) || {});
  }).catch(() => {
    reject();
  });
});

export const getFileByPatCode = (patCode) => {
	return new Promise((resolve, reject) => {
		axios.get(`files/url/byPatCode/${patCode}`).then(response => {
			resolve(_.has(response.data, 'fileUrl') ? response.data.fileUrl : '');
		}).catch(() => {
			reject('');
		});
	});
}

export const setDatetimeLocal = (datetime, format, useUTC = false, textEmptyReturn = '-') => {
	if (_.isUndefined(datetime) || datetime === '' || datetime === 'null' || !moment(new Date(datetime.toString().replace(/-/g, '/'))).isValid()) {
		return textEmptyReturn;
	}

	let result = useUTC ? moment.utc(datetime).tz(moment.defaultZone.name) : moment(datetime);
	if (i18n.language === 'th') {
		const convertYear = parseInt(result.format('YYYY')) + 543;
		return result.format(format).replace(result.format('YYYY'), convertYear);
	}
	return result.format(format);
}

export const formatCurrency = (value, decimal = 2) => {
	if (isNaN(value)) {
		return (0).toFixed(decimal);
	}
	return (value / 1).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const encrypt = (data) => {
	let message = typeof data === 'string' ? data : JSON.stringify(data);
	return CryptoJS.AES.encrypt(message, SECRET_KEY).toString();
};

export const decrypt = (ciphertext, type = 'object') => {
	try {
		let bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
		return type === 'string' ? bytes.toString(CryptoJS.enc.Utf8) : JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	} catch (error) {
		return false;
	}
};

export const randomString = (value, str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
	const shuffle = str => [...str].reduceRight((res, _, __, arr) => [...res, arr.splice(~~(Math.random() * arr.length), 1)[0]], []).join('');
	return shuffle(str).substr(0, value);
};

export const formatIDCard = (str) => {
	if (_.isUndefined(str) || str === 'null' || _.isEmpty(str)) {
		return '-';
	}
	return `${str.substring(0, 1)}-${str.substring(1, 5)}-${str.substring(5, 10)}-${str.substring(10, 12)}-${str.substring(12, 13)}`;
};

export const formatPhoneNumber = (phone) => {
	if (_.isUndefined(phone) || phone === 'null' || _.isEmpty(phone)) {
		return '-';
	}
	if (phone.substring(0, 3) === '+66' && phone.length === 12) {
		return `${phone.substring(0, 3)} ${phone.substring(3, 5)} ${phone.substring(5, 8)} ${phone.substring(8, 12)}`;
	}
	return phone;
};

export const medicineData = (item, name) => {
	switch (name) {
		case 'generics_name':
			let genericsName = [];
			if (_.has(item, 'generics') && _.isArray(item.generics)) {
				item.generics.forEach(generic => {
					genericsName.push(generic.name + ' (' + ((_.has(generic, 'compound_strength')) ? generic.compound_strength : ((_.has(generic, 'strength')) ? generic.strength : '-')) + ')');
				});
			}
			return genericsName.length > 0 ? genericsName.join(', ') : '-';
		case 'medicine_trade_name':
			return _.has(item, 'drug_recommendations.indication') && !['-', 'null', 'ไม่ระบุ'].includes(item.drug_recommendations.indication) ? item.drug_recommendations.indication : _.has(item, 'medicine.trade_name') ? item.medicine.trade_name : '-';
		case 'price':
			return _.has(item, 'price') ? item.price : _.has(item, 'retail_price') ? item.retail_price : '-'
		case 'quantity':
			return (_.has(item, 'quantity')) ? item.quantity : 1;
		default:
			return '-';
	}
}

export const summaryMedicinePrice = data => {
	let total = 0;
	_.isArray(data) && data.forEach(item => {
		let price = _.has(item, 'price') ? item.price : item.retail_price;
		total += _.has(item, 'quantity') ? (price / 1) * parseInt(item.quantity) : price;
	});
	return total;
}

export const setSchedules = data => {
	if (_.has(data, 'timeZeroTelemedSchedules') && _.isArray(data.timeZeroTelemedSchedules) && data.timeZeroTelemedSchedules.length > 0) {
		let schedules = [];
		data.timeZeroTelemedSchedules.forEach(item => {
			let index = item.index;
			if (parseInt(item.timeStart) > parseInt(item.timeEnd)) {
				index = index === 6 ? 0 : index + 1;
			}
			let start = moment.utc(moment().format('YYYY-MM-DD') + ' ' + item.timeStart).tz(moment.defaultZone.name).format('HH:mm');
			let end = moment.utc(moment().format('YYYY-MM-DD') + ' ' + item.timeEnd).tz(moment.defaultZone.name).format('HH:mm');
			if (parseInt(start) > parseInt(end)) {
				schedules.push({ index: index === 0 ? 6 : index - 1, timeStart: start, timeEnd: '00:00' });
				start = '00:00';
			}
			schedules.push({ index: index, timeStart: start, timeEnd: end });
		})
		return schedules;
	}
	return data.telemedSchedules;
}

export const getTitleMessage = (code) => {
	if ('EVENT_P001' === code) {
		return 'คุณโทรหาแพทย์แบบออนไลน์';
	} else if ('EVENT_P002' === code) {
		return 'นัดหมายแพทย์สำเร็จ รอแพทย์ยืนยันนัดหมาย แพทย์จะติดต่อกลับหาท่าน รอการแจ้งเตือนผ่าน  SMS หรือ Line';
	} else if ('EVENT_P003' === code) {
		return 'คุณกดรับสายแพทย์';
	} else if ('EVENT_P004' === code) {
		return 'คุณยอมรับการเลื่อนนัดหมาย';
	} else if ('EVENT_P005' === code) {
		return 'คุณกดปฏิเสธการเลื่อนนัดหมาย';
	} else if ('EVENT_P006' === code) {
		return 'ขอบคุณสำหรับความคิดเห็นของท่านเพื่อพัฒนาบริการของเรา';
	} else if ('EVENT_P007' === code) {
		return 'คุณต้องการรับบริการยา';
	} else if ('EVENT_P008' === code) {
		return 'คุณกดปฏิเสธรับยา';
	} else if ('EVENT_P009' === code) {
		return 'คุณยอมรับให้แพทย์โทรหาคุณตอนนี้';
	} else if ('EVENT_P010' === code) {
		return 'คุณไม่ยอมรับให้แพทย์โทรหาคุณตอนนี้';
	} else if ('EVENT_P011' === code) {
		return 'คุณเปลี่ยนเวลานัดหมาย';
	} else if ('EVENT_P012' === code) {
		return 'คนไข้เปลี่ยนแพทย์ และยกเลิกนัดหมายเดิม';
	} else {
		return 'รายละเอียดการนัดหมาย';
	}
}

export const getBodyMessage = (code, data, doctorName) => {
	if ('EVENT_P001' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คนไข้โทรหาแพทย์แบบออนไลน์",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ปัญหาสุขภาพ: " + data.information.medicalProblem,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P002' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "นัดหมายวันที่ " + moment(data.information.visitDate).format('DD/MM/YYYY') + " เวลา " + data.information.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ปัญหาสุขภาพ: " + data.information.medicalProblem,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P003' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คนไข้กดรับสายแพทย์",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
		];
	} else if ('EVENT_P004' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณได้ยอมรับการเลื่อนนัดหมาย",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "นัดหมายวันที่ " + moment(data.pendingInformation.visitDate).format('DD/MM/YYYY') + " เวลา " + data.pendingInformation.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "แพทย์จะติดต่อคุณตามเวลานัดหมายใหม่ กรุณารอการแจ้งเตือนผ่าน SMS หรือ Line",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P005' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "นัดหมายวันที่ " + moment(data.information.visitDate).format('DD/MM/YYYY') + " เวลา " + data.information.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณได้ปฏิเสธการเลื่อนนัดหมาย",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "กรุณาติดต่อเจ้าหน้าที่แอดมินผ่าน Line @doctoratoz หรือ โทร 02-8216465 ในเวลาทำการ ทุกวัน 9:00-22:00 เพื่อขอนัดหมายใหม่",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P006' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ให้คำปรึกษาวันที่ " + moment(data.information.visitDate).format('DD/MM/YYYY') + " เวลา " + data.information.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณจบสายการสนทนากับแพทย์ และให้ความคิดเห็นในการใช้งาน",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P007' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณกดยอมรับยา",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P008' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณกดปฏิเสธรับยา",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P009' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณยอมรับให้แพทย์โทรหาคุณตอนนี้ กรุณารอสักครู่และอย่าปิดหน้าจอ แพทย์กำลังติดต่อหาคุณ",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P010' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "นัดหมายวันที่ " + moment(data.information.visitDate).format('DD/MM/YYYY') + " เวลา " + data.information.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณไม่ยอมรับให้แพทย์โทรหาคุณตอนนี้ กรุณารอแพทย์ติดต่อกลับตามวันเวลาที่นัดหมาย",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P011' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณขอเปลี่ยนเวลานัดหมาย",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else if ('EVENT_P012' === code) {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "คุณเปลี่ยนเวลานัดหมาย",
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	} else {
		return [
			{
				"type": "text",
				"text": doctorName,
				"align": "center",
				"weight": "bold",
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "นัดหมายวันที่ " + moment(data.information.visitDate).format('DD/MM/YYYY') + " เวลา " + data.information.visitTime,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ปัญหาสุขภาพ: " + data.information.medicalProblem,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			},
			{
				"type": "text",
				"text": "ชื่อคนไข้: " + data.patientFirstName + " " + data.patientLastName,
				"wrap": true,
				"color": "#1d295a",
				"margin": "md",
			}
		];
	}
}

export const getActionButton = (code) => {
	if ('EVENT_P001' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P002' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P003' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P004' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P005' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P006' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P007' === code) {
		return 'กดเพื่อชำระบริการ';
	} else if ('EVENT_P008' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P009' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P010' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P011' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else if ('EVENT_P012' === code) {
		return 'ดูข้อมูลเพิ่มเติม';
	} else {
		return 'ดูข้อมูลเพิ่มเติม';
	}
}

export const getFileByDocCode = (docCode) => {
	return new Promise((resolve, reject) => {
		axios.get(`files/url/byDocCode/${docCode}`).then(response => {
			resolve(_.has(response.data, 'fileUrl') ? response.data.fileUrl : '');
		}).catch(() => {
			let imageDefault = 'https://doctoratoz-static-resources.s3.ap-southeast-1.amazonaws.com/images/doctor-male.png';
			reject(imageDefault);
		});
	});
}

export const getDataLineMessage = async (eventCode, lineUserId, referral, subDomain) => {
	return {
		"userId": lineUserId,
		"messages": [
			{
				"type": "flex",
				"altText": getTitleMessage(eventCode),
				"contents": {
					"type": "bubble",
					"header": {
						"type": "box",
						"layout": "vertical",
						"contents": [
							{
								"type": "text",
								"text": getTitleMessage(eventCode),
								"margin": "none",
								"position": "relative",
								"wrap": true,
								"color": "#1d295a",
								"weight": "bold"
							},
							{
								"type": "text",
								"text": "REF: " + referral.refCode,
								"wrap": true,
								"color": "#dddddd",
								"size": "xs"
							}
						]
					},
					"hero": {
						"type": "image",
						"url": await getFileByDocCode(referral.information.doctor),
						"align": "center",
						"gravity": "center",
						"position": "relative",
						"margin": "none",
						"aspectMode": "fit",
						"backgroundColor": "#ffffff"
					},
					"body": {
						"type": "box",
						"layout": "vertical",
						"contents": this.getBodyMessage(eventCode, referral, referral.doctorName)
					},
					"footer": {
						"type": "box",
						"layout": "vertical",
						"contents": [
							{
								"type": "button",
								"action": {
									"type": "uri",
									"label": this.getActionButton(eventCode),
									"uri": await getWebDomain()+"/account?history&value=" + referral.refId
								},
								"style": "primary",
								"margin": "none",
								"height": "sm",
								"color": "#1d295a"
							}
						]
					},
					"styles": {
						"hero": {
							"backgroundColor": "#ffffff",
							"separator": true,
							"separatorColor": "#dddddd"
						}
					}
				}
			}
		]
	};
}
export const isBrowserSupport = () => {
	return new Promise((reslove)=>{
		let userAgent = navigator.userAgent;
        let browserName;
        if(userAgent.match(/liff/i)||userAgent.match(/LIFF/i)){
			browserName = "liff";
			reslove({browser: browserName,support: false,agent: userAgent});
		}else if(userAgent.match(/line/i)||userAgent.match(/Line/i)||userAgent.match(/LINE/i)){
			browserName = "line";
			reslove({browser: browserName,support: false,agent: userAgent});
		}else if(userAgent.match(/chrome|chromium|crios/i)){
			browserName = "chrome";
			reslove({browser: browserName,support: true,agent: userAgent});
		}else if(userAgent.match(/firefox|fxios/i)){
			browserName = "firefox";
			reslove({browser: browserName,support: true,agent: userAgent});
		} else if(userAgent.match(/safari/i)){
			browserName = "safari";
			reslove({browser: browserName,support: true,agent: userAgent});
		}else if(userAgent.match(/opr\//i)){
			browserName = "opera";
			reslove({browser: browserName,support: true,agent: userAgent});
		} else if(userAgent.match(/edg/i)){
			browserName = "edge";
			reslove({browser: browserName,support: true,agent: userAgent});
		}else{
			browserName="no_defined";
			reslove({browser: browserName,support: false,agent: userAgent});
		}
	});
}

export const isAndroid = () => {
	return new Promise((reslove)=>{
		var isAndroid = /Android/i.test(navigator.userAgent);
		if(isAndroid){
			reslove(true);
		} else {
			reslove(false);
		}
	});
}
export const copyToClipboard = (str) => {
	try {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	  if (i18n.language === 'th') {
	  	alert({ type: 'success', title: 'Copy URL', message: 'สำเร็จ', icon: 'mdi:bell-ring' });
	  }else{
		alert({ type: 'success', title: 'คัดลอก URL', message: 'Successful', icon: 'mdi:bell-ring' });
	  }
	} catch (err) {}
  }

  export const getDateTimeDifference = (startDateTime, endDateTime) => {
	const startTime = new Date(startDateTime).getTime();
	const endTime = new Date(endDateTime).getTime();
	const differenceInMilliseconds = endTime - startTime;
  
	return differenceInMilliseconds;
  }

  export const convertMilliseconds = (milliseconds) => {
	const oneSecondInMilliseconds = 1000;
	const oneMinuteInMilliseconds = oneSecondInMilliseconds * 60;
	const oneHourInMilliseconds = oneMinuteInMilliseconds * 60;
	const oneDayInMilliseconds = oneHourInMilliseconds * 24;
  
	const days = Math.floor(milliseconds / oneDayInMilliseconds);
	milliseconds %= oneDayInMilliseconds;
  
	const hours = Math.floor(milliseconds / oneHourInMilliseconds);
	milliseconds %= oneHourInMilliseconds;
  
	const minutes = Math.floor(milliseconds / oneMinuteInMilliseconds);
	milliseconds %= oneMinuteInMilliseconds;
  
	const seconds = Math.floor(milliseconds / oneSecondInMilliseconds);
  
	return {
	  days,
	  hours,
	  minutes,
	  seconds,
	};
  }

export const getPrice = (data, createByApp, consultTime, isFree, t, cookie, campaigns) => {
	let price = 0, lang = 'th';
	if (_.has(data, 'doctorPriceByApps') && _.isArray(data.doctorPriceByApps)) {
		let arrPrices = data.doctorPriceByApps.filter(app => app.appName === createByApp);
		if (arrPrices.length > 0 && _.has(arrPrices[0], 'telemed.general.skill_' + lang)) {
			price = arrPrices[0].telemed.general['skill_' + lang];
		} else if (_.has(data, 'doctorPrice.telemed.general.skill_' + lang)) {
			price = data.doctorPrice.telemed.general['skill_' + lang];
		}
	} else if (_.has(data, 'doctorPrice.telemed.general.skill_' + lang)) {
		price = data.doctorPrice.telemed.general['skill_' + lang];
	}
	// Get cookie coupon
	const cookieCouponCheck = _.has(cookie, 'COUPON_CHECK') ? decrypt(cookie.COUPON_CHECK) : {};
	if (_.has(cookieCouponCheck, 'amount')) {
		if (cookieCouponCheck.useInsteadOfMoney || (cookieCouponCheck.volunteerOnly && data.commercial.includes('volunteer'))) {
			price = 0;
		} else {
			const discount = cookieCouponCheck.type === 'rate' ? (price * cookieCouponCheck.amount) / 100 : cookieCouponCheck.amount > price ? price : cookieCouponCheck.amount;
			price = price - discount;
		}
	}
	let labelCampaign = '';
	if(_.has(cookie, 'campaign') && campaigns.length > 0 && _.has(data, 'campaigns') && _.isArray(data.campaigns) && data.campaigns.length > 0){
		let indexCamp = _.findIndex(campaigns,(o)=>{
			return o.code === cookie.campaign;
		});
		let indexCampInDoctor = _.findIndex(data.campaigns,(camp)=>{
			return camp.value === cookie.campaign;
		});
		let checkApp = false;
		if(_.has(campaigns[indexCamp], 'apps')&&_.isArray(campaigns[indexCamp].apps)){
			let indexApp = _.findIndex(campaigns[indexCamp].apps,(a)=>{
				return a.value === createByApp;
			});
			if(indexApp>=0){
				checkApp = true;
			}
		}
		let cookieTime = false;
		if(_.has(cookie ,'campaign_time')){
			const dateSet = cookie.campaign_time;
			const dateCurrent = moment().format('YYYY-MM-DD HH:mm:ss');
			const timeSet = moment(dateSet);
			const timeCurrent = moment(dateCurrent);
			const diff = timeSet.diff(timeCurrent);
			const diffMinutes = parseInt((diff/1000)/60);
			if(diffMinutes>(-60)){
				cookieTime = true;
			}
		}
		if(cookieTime && checkApp && indexCamp >=0 && indexCampInDoctor >= 0 && _.has(campaigns[indexCamp], 'active') && _.has(campaigns[indexCamp], 'startDate') && _.has(campaigns[indexCamp], 'endDate') && _.has(campaigns[indexCamp], 'lockDoctorPrice')  && _.has(campaigns[indexCamp], 'doctorPrice') && campaigns[indexCamp].active && campaigns[indexCamp].lockDoctorPrice){
			const currentDate = moment().format('YYYY-MM-DD');
			const activeDate = moment(currentDate).isBetween(campaigns[indexCamp].startDate, campaigns[indexCamp].endDate); 
			if(activeDate || currentDate === campaigns[indexCamp].startDate || currentDate === campaigns[indexCamp].endDate){
				price = campaigns[indexCamp].doctorPrice;
				labelCampaign = <span className='campaign-name-text'><Icon icon="ant-design:shop-outlined" width="20px" className='campaign-store-icon' /></span>;
			}
		}
	}
	const time = _.has(data, 'timeSlotRange') ? data.timeSlotRange : consultTime;
	if ((price / 1) > 0 && (_.isUndefined(isFree) || !isFree)) {
		return [<>฿{formatCurrency(price, 0) + '/' + time} {t('min')} {labelCampaign}</>, price / 1];
	}
	return [<>{t('Free')}/{time} {t('min')}</>, 0];
};

export const numberWithCommas = (num, dot) => {
	let dotted = [];
	if (!_.isUndefined(num) && '' !== num && 'null' !== num && null !== num) {
		dotted = num.toString().split('.');
	}
	if (_.isUndefined(num) || _.isNull(num) || "" === num) {
		num = 0;
	} else {
		num = parseInt(num);
	}
	if (dot) {
		if (dotted.length >= 2) {
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + dotted[1];
		} else {
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	} else {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
};

export const checkDataAfter30Days = (date) => {
	var currentDate = new Date();
	var targetDate = new Date(date);
	var timeDiff = currentDate - targetDate;
	var daysDiff = timeDiff / (1000 * 60 * 60 * 24);
	if (daysDiff > 30) {
		return true;
	} else {
		return false;
	}
}

export const isValidURL = url => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}
